import React from 'react';

export default function App() {
    let Comp;

    if (process.env.REACT_APP_TYPE === 'widget') {
        Comp = require('./Widget').default;
    } else {
        Comp = require('./EnergyScreen').default;
    }

    return (
        <Comp/>
    )
}
