export default {

    AMOUNT_KWH: {
        icon: 'agenda.png',
        dutchReadable: 'Aantal KWH',
    },
    LED: {
        icon: 'ledlamp.png',
        dutchReadable: 'led',
    },
    SUN: {
        icon: 'zon.png',
        dutchReadable: 'Zon',
    },
    YIELD_YESTERDAY: {
        icon: 'agenda.png',
        dutchReadable: 'Opwek gisteren'
    }
}
