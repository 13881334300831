const numberFormatter = new Intl.NumberFormat('nl-NL', {style: 'decimal',});
const dateFormatter = new Intl.DateTimeFormat('nl-NL', {
    weekday: 'short',
    day: '2-digit',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit',
});

export const formatNumberAtDecimal = (number, decimal) => new Intl.NumberFormat('nl-NL', {style: 'decimal',maximumFractionDigits: decimal}).format(number);
export const formatNumber = number => numberFormatter.format(number);
export const formatDate = date => dateFormatter.format(date);
export const formatNumberReactiveDecimalCount = (number) => formatNumberAtDecimal(number, number > 100 ? 0 : (number > 10 ? 1 : 2));
