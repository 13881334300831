import React, {useEffect, useState} from 'react';
import './App.scss';
import CONSTANTS from "./constants/CONSTANTS";
import ContentBlock from "./components/ContentBlock";
import Theme from "./components/Theme";

const urlParams = new URLSearchParams(window.location.search);
const codeParam = urlParams.get('code');
const widgetParam = urlParams.get('widget');

function Widget() {
    const [widgetData, updateWidgetData] = useState({loading: true});
    const [locationData, updateLocationData] = useState({loading: true});
    const [screenInfo, updateScreenInfo] = useState({loading: true});

    useEffect(function () {
        async function fetchData() {
            if (!codeParam || !widgetParam) {
                return updateWidgetData({error: 'Incorrecte widget URL'});
            }

            fetch(`${CONSTANTS.SERVER_URL}/energyscreens/${codeParam}/${widgetParam}`, {
                method: 'GET'
            })
                .then(res => res.json())
                .then(body => {
                    updateScreenInfo(body.screenInfo ? body.screenInfo : body);
                    updateLocationData(body.location ? body.location : body);
                    updateWidgetData(body.widget ? body.widget : body);
                    // persistData(newState);
                });
        }

        fetchData();
    }, []);

    const renderWidget = () => {
        if (widgetData.loading)
            return (<p>Widget laden...</p>);

        if (widgetData.error)
            return (<p>Geen widget gevonden</p>);

        if (widgetData.Type)
            return (
                <Theme
                    theme={screenInfo.Theme}
                    brand={screenInfo.Brand}
                >
                    <ContentBlock
                        location={locationData}
                        position={null}
                        widget={widgetData}
                        theme={screenInfo.Theme}
                        brand={screenInfo.Brand}
                    />
                </Theme>
            );
    };

    return (
        <div id='widget'>
            {renderWidget()}
        </div>)

}

export default Widget;
