import React, {Fragment} from 'react';
import DataComponent from "../BlockComponents/DataComponent";
import './theme.scss';
import IconComponent from "../BlockComponents/IconComponent";
import PanelInfoComponent from "../BlockComponents/PanelInfoComponent";
import MetaphorComponent from "../BlockComponents/MetaphorComponent";
import LegacyKioskComponent from "../BlockComponents/LegacyKioskComponent";
import PropTypes from 'prop-types';

function ContentBlock(props) {
    const {slide, brand, theme, location, amountOfSlides, widget} = props;
    const component = widget || slide.Components.find(obj => obj.Position === props.position) || {};

    let comp;
    switch (component.Type) {
        case 'LegacyKioskComponent': {
            return <LegacyKioskComponent theme={theme} brand={brand}
                                         location={location}  yieldData={location.yield} message={component.News}/>
        }
        case 'MetaphorComponent': {
            comp = (<MetaphorComponent title={component.Name} power={component.power} theme={theme} brand={brand}
                                       yieldData={location.yield} amountOfSlides={amountOfSlides} slide={slide}/>);
            break;
        }
        case 'DataComponent': {
            comp = (<DataComponent title={component.Name} power={component.power} unit={component.unit}
                                   yieldData={location.yield}/>);
            break;
        }
        case 'IconComponent': {
            comp = (<IconComponent icon={component.Icon} theme={theme} brand={brand} />);
            break;
        }
        case 'PanelInfoComponent': {
            comp = (<PanelInfoComponent info={component.Info} location={location}/>);
            break;
        }
        // case 'NewsComponent': {
        //     return <NewsComponent title={component.Name} news={component.News}/>
        // }
        // case 'TwitterfeedComponent': {
        //     return <TwitterFeedComponent title={component.Name} messages={component.Messages}/>
        // }
        // case 'ImageComponent': {
        //     return <ImageComponent name={component.Name} url={component.Url}/>
        // }
        // case 'TextComponent':
        default: {
            comp = (<Fragment/>);
            break;
        }
    }

    return (
        <div className={`block block-${props.position}`}>
            {comp}
        </div>
    );
}

ContentBlock.propTypes = {
    slide: PropTypes.object,
    brand: PropTypes.string,
    theme: PropTypes.string,
    location: PropTypes.object,
    amountOfSlides: PropTypes.number,
};

ContentBlock.defaultProps = {
    amountOfSlides: 1,
};

export default ContentBlock;
