import React, {Component} from 'react';
import './theme.scss';
import SOS from "./sos";
import PropTypes from "prop-types";

class LegacyKioskComponent extends Component {

    static propTypes = {
        brand: PropTypes.any,
        theme: PropTypes.any,
        location: PropTypes.PropTypes.shape({
            Id: PropTypes.number,
            WeerOnline: PropTypes.number
        }),
        yieldData: PropTypes.shape({
            Yield: PropTypes.number,
            Power: PropTypes.number,
            Total: PropTypes.number
        }),
        message: PropTypes.string
    };

    getComponent = () => {
        const {brand, theme, location, yieldData, message} = this.props;
        //eslint-disable-next-line no-sequences
        switch (brand, theme) {
            //eslint-disable-next-line no-sequences
            case 'sos', 'primary-school':
            default: {
                return <SOS location={location} yieldData={yieldData} message={message}/>
            }
        }
    };

    render() {
        const scale = Math.min(
            window.screen.width / 1920,
            window.screen.height / 1080
        );
        console.log('scale', scale, window.screen);

        return <div className="legacycomponent" style={{transform: `scale(${scale})`}}>
            {this.getComponent()}
        </div>
    }
}

export default LegacyKioskComponent;
