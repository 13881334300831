import React, {useState} from 'react';
import metaphors from './metaphors';
import defaultConfig from "../../../config";
import {formatNumberAtDecimal} from "../../../lib/formatting";

function random_item(items) {
    return items[Math.floor(Math.random() * items.length)];
}

function getRandomMetaphor(current, power) {
    const applicable = metaphors.filter(metaphor => {
        if (current && metaphor.text === current.text)
            return false;
        if (metaphor.minValue && metaphor.minValue > power)
            return false;
        if (metaphor.maxValue && metaphor.maxValue < power)
            return false;
        return true;
    });

    return random_item(applicable);
}

function MetaphorComponent(props) {
    const {amountOfSlides, yieldData} = props;
    let [currentMetaphor, updateMetaphorKey] = useState(getRandomMetaphor(null, yieldData.Yield));

    if (amountOfSlides === 1) {
        const {slide} = props;
        const timeout = (slide.time || defaultConfig.slides.defaultSlideTime) * 1000;

        setTimeout(() => {
            updateMetaphorKey(getRandomMetaphor(currentMetaphor, yieldData.Yield));
        }, timeout);
    }

    return (
        <div className="component metaphorcomponent">
            <div className="content-block">
                <img src={require(`./../../../assets/icons/${props.brand}/${currentMetaphor.image}`)} alt={currentMetaphor.text} style={{}}/>
            </div>
            <hr/>
            <div className="title">
                <h2>
                    {formatNumberAtDecimal((yieldData.Yield) * currentMetaphor.factor, currentMetaphor.decimalCount)} {currentMetaphor.text}
                </h2>
            </div>
        </div>
    )
}

MetaphorComponent.propTypes = {};

export default MetaphorComponent;
